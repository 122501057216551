import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import contact from "../../assets/images/contact.svg";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import { MdKeyboardArrowRight } from "../../assets/icons/icons";
import * as Icon from "react-feather";
import { contactData } from "../../data/data";
import AppContext from "../../context/AppContext";

export default function ContactOne() {
  const { lang } = useContext(AppContext);
  const ContactData = contactData(lang);

  // State to handle form inputs
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone } = formData;

    // Format the message for WhatsApp
    const message =
      `*${lang === "en" ? "تواصل معنا" : "Contact Us"}*\n\n` +
      `*${lang === "en" ? "اسمك:" : "Your Name:"}* ${name}\n` +
      `*${lang === "en" ? "رقم الهاتف:" : "Your Email:"}* ${phone}\n`;

    // WhatsApp URL with the message
    const whatsappURL = `https://wa.me/201203603874?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp URL
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-36 bg-[url('../../assets/images/company/aboutus.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">
              {lang === "en" ? "تواصل معنا " : "Contact Us"}
            </h3>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={contact} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    {lang === "en" ? "تواصل معنا " : "Get in touch!"}
                  </h3>

                  <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="name" className="font-semibold">
                            {lang === "en" ? "اسمك:" : "Your Name:"}
                          </label>
                          <div className="form-icon relative mt-2">
                            <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder={lang === "en" ? "الاسم" : "Name"}
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="email" className="font-semibold">
                            {lang === "en" ? "رقم الهاتف:" : "Phone:"}
                          </label>
                          <div className="form-icon relative mt-2">
                            <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                            <input
                              name="phone"
                              id="phone"
                              type="text"
                              className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder={
                                lang === "en" ? "رقم الهاتف" : "Phone"
                              }
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mb-5">
                      <div className="text-start">
                        <label htmlFor="subject" className="font-semibold">
                          {lang === "en" ? "سؤالك:" : "Your Question:"}
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                          <input
                            name="subject"
                            id="subject"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder={lang === "en" ? "الموضوع" : "Subject"}
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="mb-5">
                      <div className="text-start">
                        <label htmlFor="comments" className="font-semibold">
                          {lang === "en" ? "تعليقك:" : "Your Comment:"}
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder={lang === "en" ? "رسالتك" : "Message"}
                            value={formData.comments}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div> */}

                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                    >
                      {lang === "en" ? "أرسل الرسالة" : "Send Message"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
