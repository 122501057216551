import axios from "axios";
import { createContext, useEffect, useState } from "react";


const AppContext = createContext();

export const AppContextProvider = (props) => {
    const { children } = props;
    let [flats, setFlats] = useState([]);
    let [regions, setRegions] = useState([]);
    let [lang, setLang] = useState("en")
    const changeLanguage = () => {

        if (lang === "ar") {
            document.documentElement.dir = "rtl"
            setLang("en")
        }
        else {
            document.documentElement.dir = "ltr"
            setLang("ar")
        }
    };

    useEffect(() => {
        const getRegions = async () => {
            try {
                const resp = await axios.get("https://home.daimooma.com/regions");
                const allRegions = resp.data;
                console.log("Fetched regions:", allRegions); // Log fetched data
                setRegions(allRegions);
            } catch (err) {
                console.error("Error fetching regions:", err);
            }
        };

        getRegions();
    }, []);
    useEffect(() => {
        console.log("Updated regions:", regions);
    }, [regions]);
    useEffect(() => {
        const getFlats = async () => {
            try {
                const resp = await axios.get("https://home.daimooma.com/flats");
                const allFlats = resp.data;
                console.log("Fetched flats:", allFlats); // Log fetched data
                setFlats(allFlats);
            } catch (err) {
                console.error("Error fetching flats:", err);
            }
        };

        getFlats();
    }, []);
    useEffect(() => {
        console.log("Updated flats:", flats);
    }, [flats]);


    return (
        <AppContext.Provider value={{ regions, flats, lang, changeLanguage }}>
            {children}
        </AppContext.Provider>
    )
}
export default AppContext;