import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import about from "../../assets/images/real/about.jpg";
import InfinityLogo from "../../assets/images/InifintyLogo.png";

import Navbar from "../../component/Navbar/navbar";
import CookieModal from "../../component/cookieModal";
import Footer from "../../component/Footer/footer";
import background from "../../assets/images/apartment.jpg";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from "react-feather";
import {
  FaArrowRight,
  LuSearch,
  LuBath,
  AiOutlineHome,
  AiOutlineDollar,
  LiaCompressArrowsAltSolid,
  LuBedDouble,
  FiCamera,
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
} from "../../assets/icons/icons";
import GetInTuch from "../../component/getInTuch";
import ContactOne from "../contact/contactOne";

import { realEstate, workData } from "../../data/dataFour";
import { feedback } from "../../data/data";
import AppContext from "../../context/AppContext";
import languages from "../../db.json";
import marketing from "../../assets/images/ronza-2-scaled.webp";
import Lightbox from "react-18-image-lightbox";
import {
  personalServices,
  portfolioData,
  portfolioImage,
} from "../../data/dataFour";

export default function IndexRealEstate() {
  let [isOpen, setOpen] = useState(false);

  const { regions, flats, lang } = useContext(AppContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImage, setIsImage] = useState(false);

  const handleMovePrev = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex + portfolioImage.length - 1) % portfolioImage.length
    );
  };

  const handleMoveNext = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % portfolioImage.length
    );
  };
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsImage(true);
  };
  const currentImage = portfolioImage[currentImageIndex];

  const WorkData = workData(lang);
  return (
    <>
      <Navbar />

      <section className="relative table w-full md:py-44 py-36">
        <div className="absolute inset-0 bg-indigo-600/5"></div>
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="md:me-6">
                <span className="text-indigo-600 font-medium">
                  {lang === "en"
                    ? "حلم المستقبل للتسويق العقاري"
                    : "Future dream for real estate markting"}
                </span>
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                  {languages[lang].welcomeTitle}
                </h4>
                <p className="text-slate-400 text-lg max-w-xl">
                  {languages[lang].welcomeDescription}
                </p>

                <div className="subcribe-form mt-6 mb-3">
                  <a
                    href="#contact"
                    className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                  >
                    {languages[lang]["Try it for free"]}
                    <FaArrowRight className="ms-2 text-[10px]" />
                  </a>
                  {/* </form> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <div className="relative">
                <img src={marketing} alt="" />
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  <Link
                    to="#"
                    onClick={() => setOpen(true)}
                    data-type="youtube"
                    data-id="CYZJZoCB858"
                    className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white"
                  >
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="CYZJZoCB858"
        onClose={() => setOpen(false)}
      />

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {lang === "en" ? "اين تجد حلم المستقبل" : "Where can you find us"}
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              {lang === "en"
                ? "مع حلم المستقبل ستجد افضل الوحدات في افضل المناطق علي الاطلاق بأسعار تنافسية"
                : "With future dream you will find your next property in the best area ever with"}
            </p>
          </div>

          <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] items-center">
            <div className="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
              <div className="grid grid-cols-1 gap-[30px]">
                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber1 />
                  </div>
                  <div className="flex-1 flex items-center sm:justify-end justify-start md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en"
                        ? "العاصمة الادارية الجديدة"
                        : "New capital city"}
                    </h4>
                  </div>
                </div>

                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber2 />
                  </div>
                  <div className="flex-1 flex sm:justify-end justify-start items-center md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en"
                        ? "مدينة العالمين الجديدة"
                        : "Alameen City"}
                    </h4>
                  </div>
                </div>

                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber3 />
                  </div>
                  <div className="flex-1 flex sm:justify-end justify-start items-center md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en" ? "الساحل الشمالي" : "North Caost"}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-4 md:col-span-12 lg:mx-8 lg:order-2 order-1">
              <img src={InfinityLogo} className="w-[300px] m-auto" alt="" />
            </div>

            <div className="lg:col-span-4 md:col-span-6 order-3">
              <div className="grid grid-cols-1 gap-[30px]">
                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber4 />
                  </div>
                  <div className="flex-1 flex justify-start items-center ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en" ? "الشيخ زايد" : "El-sheikh zayed"}
                    </h4>
                  </div>
                </div>

                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber5 />
                  </div>
                  <div className="flex-1 flex justify-start items-center ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en" ? "التجمع" : "The Settlement"}
                    </h4>
                  </div>
                </div>

                <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                  <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-red-800 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <RiNumber6 />
                  </div>
                  <div className="flex-1 flex justify-start items-center ms-4">
                    <h4 className="mb-0 text-xlg font-medium">
                      {lang === "en" ? "مدينة الغردقة" : "Hurgada city"}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container relative md:py-24 py-16" id="portfolio">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
            {languages[lang]["Gallery"]}
          </h6>
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            {languages[lang]["Best properties in the area"]}
          </h3>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
          {portfolioData.map((item, index) => {
            return (
              <div
                key={index}
                className="group relative block overflow-hidden rounded-md transition-all duration-500 h-40"
              >
                <img
                  src={item.image}
                  className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500 w-full h-full"
                  alt=""
                />
                <div className="absolute inset-0 group-hover:bg-dark opacity-50 transition duration-500 z-0"></div>

                <div className="content">
                  <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                    <Link
                      onClick={() => handleImageClick(index)}
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                    >
                      <FiCamera className="size-4" />
                    </Link>
                  </div>
                  {/* 
                  <div className="title absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                    <Link
                      to="/portfolio-detail-one"
                      className="h6 text-md font-medium text-white hover:text-indigo-600 duration-500 ease-in-out"
                    >
                      {item.name}
                    </Link>
                    <p className="text-slate-100 tag mb-0">{item.title}</p>
                  </div> */}
                </div>
              </div>
            );
          })}

          {isImage && (
            <Lightbox
              mainSrc={currentImage}
              prevSrc={
                portfolioImage[
                  (currentImageIndex + portfolioImage.length - 1) %
                    portfolioImage.length
                ]
              }
              nextSrc={
                portfolioImage[(currentImageIndex + 1) % portfolioImage.length]
              }
              onCloseRequest={() => setIsImage(false)}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </div>
      </section>

      <section className="relative md:pb-24 pb-16">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative -mt-32">
              <div className="grid grid-cols-1"></div>
            </div>
          </div>
        </div>

        <div id="services" className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {languages[lang].ourServices}
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              {languages[lang].serviceDesc}
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {WorkData.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden text-center"
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-md transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                      <Icons className="size-8" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <h5 className="text-xl font-medium">{item.title}</h5>
                    <p className="text-slate-400 mt-3">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
              {languages[lang]["flats"]}
            </h6>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-20 ">
            {flats.map((data) => {
              const region = regions.find((region) => data.c_id == region.id);
              return (
                <div
                  key={flats.id}
                  className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-96"
                >
                  <div className="relative">
                    <img
                      src={data.image_url}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />

                    <div className="absolute top-6 end-6">
                      {/* <Link
                                        to="#"
                                        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"
                                    >
                                        <i className="mdi mdi-heart"></i>
                                    </Link> */}
                    </div>

                    {/* Rating and Price */}
                    <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent text-white">
                      <ul className="flex justify-between items-center">
                        {/* <span className="text-slate-300">Price</span> */}
                        {/* <li>
                                        <p className="text-lg font-medium">EGP{data.price}</p>
                                        </li> */}
                        <li>
                          {/* <span className="text-slate-300">Rating</span> */}
                          <ul className="text-lg font-medium text-amber-400 list-none flex items-center">
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline text-white">5.0(30)</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-6">
                    {region && (
                      <div className="pb-6">
                        <Link
                          to={`/details?id=${data.id}`}
                          className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500"
                        >
                          {region.name}
                        </Link>
                      </div>
                    )}
                    <div className="pb-6">
                      <Link
                        to="#"
                        className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500 text-slate-600"
                      >
                        {data.title}
                      </Link>
                    </div>
                    {/* <details
                                        style={{ cursor: 'pointer' }}
                                        className="text-lg text-slate-600 hover:text-indigo-600 font-medium ease-in-out duration-500"
                                    >
                                        <summary className="font-medium">
                                        شاهد التفاصيل
                                        </summary>
                                        <div style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        {data.desc} {data.features}
                                        </div>
                                    </details> */}

                    <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex items-center list-none">
                      {/* <li className="flex items-center me-4">
                                        <LiaCompressArrowsAltSolid className="text-2xl me-2 text-indigo-600" />
                                        <span>{data.area}</span>
                                    </li>

                                    <li className="flex items-center me-4">
                                        <LuBedDouble className="text-2xl me-2 text-indigo-600" />
                                        <span>{data.rooms}</span>
                                    </li> */}

                      <li className="flex items-center">
                        <LuBath className="text-2xl me-2 text-indigo-600" />
                        <span>{data.bathrooms}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="md:flex justify-center text-center mt-6">
            <div className="md:w-full">
              <Link
                to="/flats"
                className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
              >
                {lang === "en" ? "شاهد المزيد" : "Watch more"}
                <FaArrowRight className="ms-2 text-[10px]" />{" "}
              </Link>
            </div>
          </div>
        </div>

        {/* <GetInTuch/> */}
        <br />
        <div id="contact">
          <ContactOne />
        </div>
      </section>

      <Footer />
    </>
  );
}
