import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import image from "../../assets/images/real/property/single/1.jpg";
import image1 from "../../assets/images/real/property/single/2.jpg";
import image2 from "../../assets/images/real/property/single/3.jpg";
import image3 from "../../assets/images/real/property/single/4.jpg";
import image4 from "../../assets/images/real/property/single/5.jpg";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import { propertyListing } from "../../data/data";

import {
  FiPhone,
  FiCamera,
  LiaCompressArrowsAltSolid,
  LuBedDouble,
  LuBath,
} from "../../assets/icons/icons";
import AppContext from "../../context/AppContext";
import axios from "axios";

const getFlat = async (id) => {
  try {
    const resp = await axios.get("https://home.daimooma.com/flats/" + id);
    return resp.data;
  } catch (err) {}
};

export default function PropertyDetail() {
  const [flat, setFlat] = useState({});

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    getFlat(id).then((f) => {
      setFlat(f);
    });
  }, []);

  let [photoIndex, setActiveIndex] = useState(0);
  let [isOpen, setOpen] = useState(false);

  let images = [flat?.image_url];

  let handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  const { lang } = useContext(AppContext);
  return (
    <>
      <Navbar navClass="nav-sticky" />
      <section className="relative md:pb-24 pb-16 mt-20">
        <div className="container-fluid relative">
          <div className="md:flex mt-4 justify-center">
            <div className="lg:w-1/2 md:w-1/2 p-1">
              <div className="group relative overflow-hidden">
                <img src={flat?.image_url} alt="" />
                <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                  <Link
                    onClick={() => handleCLick(0)}
                    className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                  >
                    <FiCamera className="size-4" />
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="lg:w-1/2 md:w-1/2">
              <div className="flex">
                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={image1} alt="" />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                      <Link
                        onClick={() => handleCLick(1)}
                        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={image2} alt="" />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                      <Link
                        onClick={() => handleCLick(2)}
                        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={image3} alt="" />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                      <Link
                        onClick={() => handleCLick(3)}
                        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={image4} alt="" />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                      <Link
                        onClick={() => handleCLick(4)}
                        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>


            </div> */}

            
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="md:flex">
            <div className="lg:w-2/3 md:w-1/2 md:p-4 px-3">
              <h4 className="text-2xl font-medium">{flat?.address}</h4>

              <ul className="py-6 flex items-center list-none">
                <li className="flex items-center lg:me-6 me-4">
                  <LiaCompressArrowsAltSolid className=" lg:text-3xl text-2xl me-2 text-indigo-600" />
                  <span className="lg:text-xl">{flat?.area}</span>
                </li>

                <li className="flex items-center lg:me-6 me-4">
                  <LuBedDouble className="lg:text-3xl text-2xl me-2 text-indigo-600" />
                  <span className="lg:text-xl">{flat?.rooms}</span>
                </li>

                <li className="flex items-center">
                  <LuBath className="lg:text-3xl text-2xl me-2 text-indigo-600" />
                  <span className="lg:text-xl">{flat?.bathrooms}</span>
                </li>
              </ul>

              <p className="text-slate-400">{flat?.desc}</p>

              {/* <div className="w-full leading-[0] border-0 mt-6">
                                <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" title="Google Maps Embed" style={{ "border": "0" }} className="w-full h-[500px]" allowFullScreen></iframe>

                            </div> */}
            </div>

            <div className="lg:w-1/3 md:w-1/2 md:p-4 px-3 mt-8 md:mt-0">
              <div className="sticky top-20">
                <div className="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-800">
                  <div className="p-6">
                    <h5 className="text-2xl font-medium">
                      {lang === "en" ? ":السعر" : "Price :"}
                    </h5>

                    <div className="flex justify-between items-center mt-4">
                      <span className="text-xl font-medium">
                        EGP {flat.price}
                      </span>

                      {/* <span className="bg-indigo-600/10 text-indigo-600 text-sm px-2.5 py-0.75 rounded h-6">For Sale</span> */}
                    </div>

                    <ul className="list-none space-x-1 mt-4">
                      {/* <li className="flex justify-between items-center">
                                                <span className="text-slate-400 text-sm">Days on Hously</span>
                                                <span className="font-medium text-sm">124 Days</span>
                                            </li> */}

                      <li className="flex justify-between items-center mt-2">
                        <span className="text-slate-400 text-sm">
                          {lang === "en"
                            ? ":مساحة المتر المربع"
                            : "Price per sq ft"}
                        </span>
                        {/* <span className="font-medium text-sm">EGP {flat?.price / flat?.area}</span> */}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-12 text-center">
                  <h3 className="mb-6 text-xl leading-normal font-medium">
                    {lang === "en"
                      ? "هل لديك سؤال ؟ اتصل بنا "
                      : "Have Question ? Get in touch"}
                  </h3>

                  <div className="mt-6">
                    <a
                      href={`https://wa.me/201203603874?text=استفسار عن ${flat?.name} - ${flat?.address}`}
                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-transparent hover:bg-red-700 border border-indigo-600 text-indigo-600 hover:text-white rounded-md"
                    >
                      <FiPhone className="me-1 text-lg" />
                      {lang === "en" ? "إتصل بنا" : "Contact us"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}
