import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import s1 from "../../assets/images/shop/items/s1.jpg";
import s2 from "../../assets/images/shop/items/s2.jpg";
import s3 from "../../assets/images/shop/items/s3.jpg";

import ShopFooter from "../../component/Footer/shopFooter";
import EcommerceNavbar from "../../component/Navbar/ecommerceNavbar";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";

import * as Icon from "react-feather";
import {
  LiaCompressArrowsAltSolid,
  LuBath,
  LuBedDouble,
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "../../assets/icons/icons";
import MobileApp from "../../component/mobileApp";

import { productData, shopGrid } from "../../data/dataTwo";
import { realEstate } from "../../data/dataFour";
import AppContext from "../../context/AppContext";

export default function ShopGridTwo() {
  const { regions, flats, lang } = useContext(AppContext);
  const [shopGridData, setShopGridData] = useState(shopGrid);
  const [activeFilter, setActiveFilter] = useState("all");

  const [filteredFlats, setFilteredFlats] = useState(flats);
  const onClickCategory = (gender) => {
    setShopGridData(
      shopGrid.map((data) => {
        return gender === data.gender
          ? { ...data, checked: true }
          : { ...data, checked: false };
      })
    );
  };
  const filterFlats = (e) => {
    const regionID = e.target.value;
    setActiveFilter(regionID);
    if (regionID === "all") {
      setFilteredFlats(flats);
    } else {
      const filtered = flats.filter((photo) => photo.c_id == regionID);
      console.log(filtered);

      if (filtered.length === 0) {
        setFilteredFlats(flats);
      } else {
        setFilteredFlats(filtered);
      }
    }
  };
  return (
    <>
      <Navbar />
      {/* <section className="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">Shop Grid</h3>
                    </div>

                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index-shop">Techwind</Link></li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5"><MdKeyboardArrowRight className="text-xl"/></li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600" aria-current="page">Product Grid Two</li>
                        </ul>
                    </div>
                </div>
            </section> */}

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="w-full">
            {" "}
            {/*grid md:grid-cols-12 grid-cols-1 gap-[30px]*/}
            {/* <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-800 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <form>
                                    <div className="grid grid-cols-1 gap-3">
                                        <div>
                                            <label htmlFor="searchname" className="hidden font-semibold"></label>
                                            <div className="relative">
                                                <Icon.Search className="size-4 absolute top-3 start-3"></Icon.Search>

                                                <input name="search" id="searchname" type="text" className="form-input w-full py-2 px-3 h-10 ps-9 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Search" />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="font-semibold">Categories</label>
                                            {shopGridData.map((data,index) => {
                                                return (
                                                    <div className="block mt-2" key={index}>
                                                        <div>
                                                            <label className="inline-flex items-center">
                                                                <input type="radio" onClick={() => onClickCategory(data.gender)} className="form-radio border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2" name="radio-colors" checked={data.checked} value="1" readOnly />
                                                                <span className="text-slate-400">{data.gender}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                        <div>
                                            <label className="font-semibold">Top Products</label>
                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={s1} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">T-shirt (M)</span>
                                                        <span className="block text-sm text-slate-400">$16USD</span>
                                                    </span>
                                                </span>
                                            </div>

                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={s2} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">Bag</span>
                                                        <span className="block text-sm text-slate-400">$16USD</span>
                                                    </span>
                                                </span>
                                            </div>

                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={s3} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">Watch (Men)</span>
                                                        <span className="block text-sm text-slate-400">$16USD</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <input type="submit" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" value="Apply Filter" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
            <div className="lg:col-span-8 md:col-span-6">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-9 md:col-span-8">
                  <h3 className="text-xl leading-normal font-semibold">
                    {lang === "en" ? "النتائج" : "Results"}
                  </h3>
                </div>

                {/*Sorting*/}

                <div className="lg:col-span-3 md:col-span-4 md:text-end">
                  <label className="font-semibold hidden"></label>
                  <select
                    onChange={filterFlats}
                    className="form-select form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  >
                    {regions.map((data) => {
                      <option value="all">
                        &nbsp;&nbsp;&nbsp; &nbsp;اختر حسب المنطقة{" "}
                      </option>;

                      return (
                        <option key={data.id} value={data.id}>
                          &nbsp;&nbsp;&nbsp; &nbsp;{data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                                {productData.map((item,index)=>{
                                return(
                                    <div className="group" key={index}>
                                    <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
                                        <img src={item.image} alt="" />
        
                                        <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
                                            <Link to="/shop-cart" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md">Add to Cart</Link>
                                        </div>
        
                                        <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                            <li><Link className="size-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"><i className="mdi mdi-heart"></i></Link></li>
                                            <li className="mt-1"><Link to={`/shop-item-detail/${item.id}`} className="size-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"><i className="mdi mdi-eye-outline"></i></Link></li>
                                            <li className="mt-1"><Link className="size-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"><i className="mdi mdi-bookmark-outline"></i></Link></li>
                                        </ul>
                                        {item.tag ? <ul className="list-none absolute top-[10px] start-4">
                                            <li><Link className={`${item.tagBg} text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5`}>{item.tag}</Link></li>
                                        </ul> :""}
                                    </div>
    
                                <div className="mt-4">
                                    <Link to={`/shop-item-detail/${item.id}`} className="hover:text-indigo-600 text-lg font-semibold">{item.name}</Link>
                                    <div className="flex justify-between items-center mt-1">
                                        <p className="text-green-600">{item.descAmount} <del className="text-red-600">{item.amount}</del></p>
                                        <ul className="font-medium text-amber-400 list-none space-x-1">
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )
                                })}
                            </div> */}

              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-20 w-full ">
                {(filteredFlats.length > 0 ? filteredFlats : flats).map(
                  (data) => {
                    const region = regions.find(
                      (region) => data.c_id == region.id
                    );
                    return (
                      <>
                        <div
                          key={data.id}
                          className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-96"
                        >
                          <div className="relative">
                            <img
                              src={data.image_url}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />

                            <div className="absolute top-6 end-6">
                              {/* <Link
        to="#"
        className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"
      >
        <i className="mdi mdi-heart"></i>
      </Link> */}
                            </div>

                            {/* Rating and Price */}
                            <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent text-white">
                              <ul className="flex justify-between items-center">
                                {/* <span className="text-slate-300">Price</span> */}
                                {/* <li>
          <p className="text-lg font-medium">EGP{data.price}</p>
        </li> */}
                                <li>
                                  {/* <span className="text-slate-300">Rating</span> */}
                                  <ul className="text-lg font-medium text-amber-400 list-none flex items-center">
                                    <li className="inline">
                                      <i className="mdi mdi-star"></i>
                                    </li>
                                    <li className="inline">
                                      <i className="mdi mdi-star"></i>
                                    </li>
                                    <li className="inline">
                                      <i className="mdi mdi-star"></i>
                                    </li>
                                    <li className="inline">
                                      <i className="mdi mdi-star"></i>
                                    </li>
                                    <li className="inline">
                                      <i className="mdi mdi-star"></i>
                                    </li>
                                    <li className="inline text-white">
                                      5.0(30)
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="p-6">
                            {region && (
                              <div className="pb-6">
                                <Link
                                  to={`/details?id=${data.id}`}
                                  className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500"
                                >
                                  {region.name}
                                </Link>
                              </div>
                            )}
                            <div className="pb-6">
                              <Link
                                to="#"
                                className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500 text-slate-600"
                              >
                                {data.title}
                              </Link>
                            </div>
                            {/* <details
        style={{ cursor: 'pointer' }}
        className="text-lg text-slate-600 hover:text-indigo-600 font-medium ease-in-out duration-500"
      >
        <summary className="font-medium">
          شاهد التفاصيل
        </summary>
        <div style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {data.desc} {data.features}
        </div>
      </details> */}

                            <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex items-center list-none">
                              {/* <li className="flex items-center me-4">
        <LiaCompressArrowsAltSolid className="text-2xl me-2 text-indigo-600" />
        <span>{data.area}</span>
      </li>

      <li className="flex items-center me-4">
        <LuBedDouble className="text-2xl me-2 text-indigo-600" />
        <span>{data.rooms}</span>
      </li> */}

                              <li className="flex items-center">
                                <LuBath className="text-2xl me-2 text-indigo-600" />
                                <span>{data.bathrooms}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                  <nav aria-label="Page navigation example">
                    <ul className="inline-flex items-center -space-x-px">
                      {/*backword arrow*/}

                      {/* <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                                     <MdOutlineKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                                </Link>
                                            </li> */}
                      {/* <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">1</Link>
                                            </li>
                                            <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">2</Link>
                                            </li>
                                            <li>
                                                <Link to="/#" aria-current="page" className="z-10 size-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600">3</Link>
                                            </li>
                                            <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">4</Link>
                                            </li>
                                            <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">5</Link>
                                            </li> */}

                      {/*forward arrow*/}

                      {/* <li>
                                                <Link to="/#" className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                                    <MdKeyboardArrowRight className="text-xl rtl:rotate-180 rtl:-mt-1"/>
                                                </Link>
                                            </li> */}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <MobileApp/> */}
      </section>
      <Footer />
    </>
  );
}
